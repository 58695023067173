/**
 * Port of external-remotes-plugin template resolution strat
 * Ref: https://github.com/module-federation/external-remotes-plugin/blob/main/index.js
 */
const toExpression = (templateUrl: string): string => {
    const result: string[] = [];
    const current: string[] = [];
    let isExpression: boolean = false;
    let invalid: boolean = false;

    for (const c of templateUrl) {
        if (c === '[') {
            if (isExpression) {
                invalid = true;
                break;
            }
            isExpression = true;
            if (current.length) {
                result.push(`${current.join('')}`);
                current.length = 0;
            }
        } else if (c === ']') {
            if (!isExpression) {
                invalid = true;
                break;
            }
            isExpression = false;
            if (current.length) {
                result.push(`${current.join('')}`);
                current.length = 0;
            }
            current.length = 0;
        } else {
            current.push(c);
        }
    }

    if (isExpression || invalid) {
        throw new Error(`Invalid template URL "${templateUrl}"`);
    }

    if (current.length) {
        result.push(`${current.join('')}`);
    }

    return result.join(' + ');
};

export default toExpression;
