interface ModuleResult {
    __esModule?: boolean;
    default: (id: string, error?: Error) => never;
}

const offlineRemote = () => {
    const fallback = (id: string, error?: Error): never => {
        throw Error(
            `Failed to load remote module| Looks like [${id}] is offline| Please try again later|`,
        );
    };

    const getModule = (
        pg: typeof fallback,
        from: string,
    ): (() => ModuleResult) | ModuleResult => {
        if (from === 'build') {
            return () => ({
                __esModule: true,
                default: pg,
            });
        }
        return {
            default: pg,
        };
    };

    return {
        name: 'offline-remote-plugin',
        errorLoadRemote({
            id,
            error,
            from,
        }: {
            id: string;
            error?: Error;
            from: string;
        }) {
            return getModule(fallback(id, error), from);
        },
    };
};

export default offlineRemote;
